'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_4']} flex-col indexResponse_box_4__mn_tr`}>
        <div className={`${styles['section_11']} flex-col indexResponse_section_11__3Jzl8`} />
        <span className={`${styles['text_13']} indexResponse_text_13__1awIP bold`}>留学——改变人生的奇妙之旅</span>
        <div className={`${styles['text-group_21']} flex-col justify-between indexResponse_text-group_21__Egfos`}>
          <span className={`${styles['paragraph_1']}`}>
            前往新的国家。感知不同的文化。结交世界各地的朋友。出国留学是一生难得的机会。这是你开启留学之旅的机会。
            <br />
            你还可以在一些世界顶尖大学继续学术深造。
          </span>
          <span className={`${styles['text_14']} bold`}>#热门出国留学目的地</span>
        </div>
        <div className={`${styles['list_3']} flex-row`}>
          <div className={`${styles['list-items_1-0']} flex-col indexResponse_list-items_1-0__2LkCc clickable`}>
            <span className={`${styles['text_15-0']}`}>留学英国</span>
            <span className={`${styles['text_16-0']}`}>英国以其历史、传统、文化和风景而闻名，是一个独特的多元文化国家。</span>
            <div className={`${styles['group_2-0']} flex-row indexResponse_group_2-0__IuCuN`}>
              <div className={`${styles['image-text_28-0']} flex-row justify-between`}>
                <span className={`${styles['text-group_3-0']}`}>探索英国</span>
                <img
                  className={`${styles['label_2-0']}`}
                  src={
                    require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${styles['list-items_1-1']} flex-col indexResponse_list-items_1-1__2CuqK clickable`}>
            <span className={`${styles['text_15-1']}`}>留学美国</span>
            <span className={`${styles['text_16-1']}`}>辽阔、多元且热情的美国是国际学生的首选目的地。</span>
            <div className={`${styles['group_2-1']} flex-row indexResponse_group_2-1__1ChJu`}>
              <div className={`${styles['image-text_28-1']} flex-row justify-between`}>
                <span className={`${styles['text-group_3-1']}`}>探索美国</span>
                <img
                  className={`${styles['label_2-1']}`}
                  src={
                    require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${styles['list-items_1-2']} flex-col indexResponse_list-items_1-2__2e5eM clickable`}>
            <span className={`${styles['text_15-2']}`}>留学澳大利亚</span>
            <span className={`${styles['text_16-2']}`}>
              在澳大利亚，所有大学的海外学生占比为25％。这就是这个国家如此受留学生欢迎的原因。
            </span>
            <div className={`${styles['group_2-2']} flex-row indexResponse_group_2-2__3jQPE`}>
              <div className={`${styles['image-text_27-2']} flex-row justify-between indexResponse_image-text_27-2__2jc_t`}>
                <span className={`${styles['text-group_2-2']}`}>探索澳大利亚</span>
                <img
                  className={`${styles['label_1-2']}`}
                  src={
                    require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <span className={`${styles['text_17']} bold`}>#为什么要出国留学？</span>
        <div className={`${styles['section_3']} flex-col`}>
          <div className={`${styles['text-wrapper_9']} flex-row`}>
            <span className={`${styles['text_18']}`}>选择海外留学的原因：</span>
          </div>
          <div className={`${styles['box_19']} flex-row justify-between`}>
            <div className={`${styles['section_12']} flex-col justify-between indexResponse_section_12__2lWgb`}>
              <div className={`${styles['image-text_29']} flex-row justify-between`}>
                <div className={`${styles['box_6']} flex-col indexResponse_box_6__3mdl5`} />
                <span className={`${styles['text-group_4']}`}>探索神奇的地方</span>
              </div>
              <div className={`${styles['image-text_30']} flex-row justify-between`}>
                <div className={`${styles['block_4']} flex-col  indexResponse_block_4__3XObX`} />
                <span className={`${styles['text-group_5']}`}>获得新的文化体验和视角</span>
              </div>
              <div className={`${styles['image-text_31']} flex-row justify-between`}>
                <div className={`${styles['block_5']} flex-col indexResponse_block_5__1aIg8`} />
                <span className={`${styles['text-group_6']}`}>提高你的外语语言能力</span>
              </div>
            </div>

            <div className={`${styles['section_12']} flex-col justify-between`}>
              <div className={`${styles['image-text_29']} flex-row justify-between`}>
                <div className={`${styles['box_6']} flex-col`} />
                <span className={`${styles['text-group_4']}`}>提升你的职业前景</span>
              </div>
              <div className={`${styles['image-text_30']} flex-row justify-between`}>
                <div className={`${styles['block_4']} flex-col`} />
                <span className={`${styles['text-group_5']}`}>结交终身朋友</span>
              </div>
              <div className={`${styles['image-text_31']} flex-row justify-between`}>
                <div className={`${styles['block_5']} flex-col`} />
                <span className={`${styles['text-group_6']}`}>很有趣！</span>
              </div>
            </div>

          </div>
          <div className={`${styles['box_20']} flex-row`}>
            <div className={`${styles['image-text_35']} flex-row justify-between`}>
              <div className={`${styles['group_3']} flex-col indexResponse_group_3__29kJI`} />
              <span className={`${styles['text-group_10']}`}>个人和专业发展</span>
            </div>
          </div>
        </div>
        <span className={`${styles['text_22']} bold`}>#出国留学的流程</span>
        <div className={`${styles['section_4']} flex-col indexResponse_section_4__8MOlk`}>
          <span className={`${styles['text_23']}`}>助你留学</span>
          <span className={`${styles['text_24']}`}>
            想成为海外留学生吗？从签证到课程选择再到入学要求，我们经验丰富的团队将在这里回答你所有的留学问题。
          </span>
          <div className={`${styles['box_10']} flex-row clickable`}>
            <div className={`${styles['image-text_36']} flex-row justify-between `}>
              <span className={`${styles['text-group_11']}`}>海外留学支持</span>
              <img
                className={`${styles['label_3']}`}
                src={
                  require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;

'use strict';

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import styles from './assets/indexResponse.module.css';
import './assets/private_header.css';


const print = function (value) { };
class DDS_lanhupage_Header extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['section_1']} flex-col indexResponse_section_1__g6BUm`}>

        <div className={`${styles['section_9']} flex-row indexResponse_section_9__3IxsO`}>
          <img
            className={`${styles['image_1']}`}
            src={
              require('./assets/img/SketchPng2c67d02c78f8739b055b72fd9eedf681c0aac014c9606fe372365ed047a7ab32.png')
            }
          />
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPng1c57a775c7374eb4fadd4b1fe0385f93316e14f780696ffc6e131eb94c504e04.png')
            }
          />
          <img
            className={`${styles['image_3']}`}
            src={
              require('./assets/img/SketchPng3b0c7a0552b44d98e0e551b990f6240c858677fc0af6030f981cf5752e6cb86f.png')
            }
          />
          <span className={`${styles['text_1']} indexResponse_text_1__3KntK`} >
            {this.props.activeMenu == "0" && <div className={`${styles['section_8']} flex-row indexResponse_section_8__CEYtV`}>
              <div className={`${styles['box_1']} flex-col`} />
            </div>
            }
            <Link to="/" className={this.props.activeMenu == "0" ? 'active' : ''}>首页</Link></span>
          <span className={`${styles['text_2']} indexResponse_text_2__2IG7N`}>
            {this.props.activeMenu == "1" && <div className={`${styles['section_8']} flex-row indexResponse_section_8__CEYtV`}>
              <div className={`${styles['box_1']} flex-col indexResponse_box_1__1FnRE`} />
            </div>
            }
            <Link to='/country' className={this.props.activeMenu == "1" ? 'active' : ''}>选择国家</Link></span>
          <span className={`${styles['text_3']}`}>
            {this.props.activeMenu == "2" && <div className={`${styles['section_8']} flex-row`}>
              <div className={`${styles['box_1']} flex-col`} />
            </div>
            }
            <Link to='/country' className={this.props.activeMenu == "2" ? 'active' : ''}>选择项目</Link></span>
          <span className={`${styles['text_4']}`}>
            {this.props.activeMenu == "3" && <div className={`${styles['section_8']} flex-row`}>
              <div className={`${styles['box_1']} flex-col`} />
            </div>
            }
            <Link to='/country' className={this.props.activeMenu == "3" ? 'active' : ''}>选择大学</Link></span>
          <span className={`${styles['text_5']}`}>
            {this.props.activeMenu == "4" && <div className={`${styles['section_8']} flex-row`}>
              <div className={`${styles['box_1']} flex-col`} />
            </div>
            }
            <Link to='/country' className={this.props.activeMenu == "4" ? 'active' : ''}>关于我们</Link></span>
          <div className={`${styles['group_1']} flex-col`} />
          <span className={`${styles['text_6']}`}>400-888-8888</span>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_Header;

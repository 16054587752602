'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_4 extends Component {
  state = {};
  setValue(val) { }
  onNextSlide = () => {
    //var i = this.state.currentSlideIndex + 1;
    //this.props.onClickNextcurrentSLide(i)
    document.getElementById('mainwrap').scrollBy({
      top: 20, // 垂直滚动500px
      left: 0, // 水平位置（这里不改变）
      behavior: 'smooth' // 平滑滚动效果
    });
  }
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_5']} flex-col justify-center`}>
          <div className={`${styles['group_14']} flex-col`}>
            <div className={`${styles['group_15']} flex-col`}>
              <div className={`${styles['group_25']} flex-row fade-out`}>
                <div className={`${styles['group_17']} flex-col`}>
                  <span className={`${styles['paragraph_2']} indexResponse_paragraph_2__2B6dN slide-right`}>
                    2+2
                    <br />
                    国际本科
                  </span>
                  <div className={`${styles['group_18']} flex-col`} />
                  <div className={`clickable ${styles['group_19']} flex-row`}>
                    <div className={`${styles['image-text_4']} flex-row justify-between `}>
                      <span className={`${styles['text-group_2']}`}>查看详情</span>
                      <img
                        className={`${styles['label_2']}`}
                        src={
                          require('./assets/img/SketchPngd5cc31c7e94b2414b3af4c585037852c61505118557607dec9e414b31c008970.png')
                        }
                      />
                    </div>
                  </div>
                </div>
                <span className={`${styles['text_11']} indexResponse_text_11__1QV02`}>
                  国内俩年（预科+大一）、海外大学二年——完成本科学习，获教育部认证的学士学位。
                </span>
                <div className={`${styles['group_22']} flex-col indexResponse_group_22__25cCy`} />
                <span className={`${styles['text_4']} indexResponse_text_4__1Uozq`}>Multi-Agent&nbsp;System</span>
              </div>
              <div className={`${styles['group_26']} flex-row indexResponse_group_26__1mw-b`}>
                <div className={`${styles['group_1']} flex-col`}>
                  <div className={`${styles['group_2']} flex-col indexResponse_group_2__1e-Kt`}>
                    <div className={`${styles['group_3']} flex-col`} />
                  </div>
                  <img
                    className={`clickable ${styles['image_1']}`}
                    src={
                      require('./assets/img/SketchPng4420ce05fbeb75905a3c3c0691d5031b4627da5c16d38d4503a7de6d9fb91413.png')
                    }
                  />
                </div>
              </div>
              <div className={`${styles['image-wrapper_5']} flex-row`}>
                <img
                  className={`clickable ${styles['image_2']}`}
                  onClick={() => this.onNextSlide()}
                  src={
                    require('./assets/img/SketchPngd08825a154ca581054fc75372f7edca364d9ed279fa43b889c445ea09526f894.png')
                  }
                />
              </div>
              <div className={`${styles['image-wrapper_6']} flex-row`}>
                <img
                  className={`clickable ${styles['image_3']}`}
                  src={
                    require('./assets/img/SketchPng1043bd5c5c67398c345b9723acb5e8d9485cafdf0c89eca728c1f52603b44a47.png')
                  }
                />
              </div>
              <div className={`${styles['section_3']} flex-col indexResponse_section_3__2Gvv1`}>
                <div className={`${styles['section_4']} flex-col`} />
                <div className={`${styles['group_6']} flex-col`} />
                <div className={`${styles['section_5']} flex-col`} />
                <div className={`${styles['section_6']} flex-col`} />
                <span className={`${styles['text_3']}`}>1+3艺术预科</span>
                <div className={`${styles['section_7']} flex-col`} />
                <img
                  className={`${styles['image_4']} indexResponse_image_4__2yw9A`}
                  src={
                    require('./assets/img/SketchPng2a8675b81e27764903f1304c221689ced87ea3dbbfa3996be807f85d83d596d3.png')
                  }
                />
                <div className={`${styles['group_24']} flex-row indexResponse_group_24__1W766 animate_slide`}>
                  <span className={`${styles['text_12']}`}>时间节点</span>
                  <div className={`clickable ${styles['box_6']} flex-row`}>
                    <div className={`${styles['text-group_8']} flex-col justify-between`}>
                      <div className={`${styles['text-wrapper_7']}`}>
                        <span className={`${styles['text_13']}`}>8-9</span>
                        <span className={`${styles['text_14']}`}>&nbsp;月</span>
                      </div>
                      <span className={`${styles['text_15']}`}>选定专业</span>
                    </div>
                  </div>
                  <div className={`clickable ${styles['box_7']} flex-row`}>
                    <div className={`${styles['text-group_9']} flex-col justify-between`}>
                      <div className={`${styles['text-wrapper_8']}`}>
                        <span className={`${styles['text_16']}`}>次年</span>
                        <span className={`${styles['text_17']}`}>3</span>
                        <span className={`${styles['text_18']}`}>&nbsp;月</span>
                      </div>
                      <span className={`${styles['text_19']}`}>完成海外大学首次申请</span>
                    </div>
                  </div>
                  <div className={`clickable ${styles['box_8']} flex-row`}>
                    <div className={`${styles['text-group_10']} flex-col justify-between`}>
                      <div className={`${styles['text-wrapper_9']}`}>
                        <span className={`${styles['text_20']}`}>次年</span>
                        <span className={`${styles['text_21']}`}>6</span>
                        <span className={`${styles['text_22']}`}>&nbsp;月</span>
                      </div>
                      <span className={`${styles['text_23']}`}>完成预科学习</span>
                    </div>
                  </div>
                  <div className={`clickable ${styles['box_9']} flex-row`}>
                    <div className={`${styles['text-group_11']} flex-col justify-between`}>
                      <div className={`${styles['text-wrapper_10']}`}>
                        <span className={`${styles['text_24']}`}>末年</span>
                        <span className={`${styles['text_25']}`}>3</span>
                        <span className={`${styles['text_26']}`}>&nbsp;月</span>
                      </div>
                      <span className={`${styles['text_27']}`}>完成海外大学二次申请</span>
                    </div>
                  </div>
                  <div className={`clickable ${styles['box_10']} flex-row`}>
                    <div className={`${styles['text-group_12']} flex-col justify-between`}>
                      <div className={`${styles['text-wrapper_11']}`}>
                        <span className={`${styles['text_28']}`}>末年</span>
                        <span className={`${styles['text_29']}`}>6</span>
                        <span className={`${styles['text_30']}`}>&nbsp;月</span>
                      </div>
                      <span className={`${styles['text_31']}`}>完成大一阶段学习，出国前准备，办理签证。</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_4;

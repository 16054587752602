import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import Home from './Home'
import ChooseCountry from './Country'

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/country" component={ChooseCountry} />
                        <Redirect from="/" to="/home" />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App

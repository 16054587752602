'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_1 extends Component {
  state = {};
  setValue(val) { }
  constructor(props) {
    super(props);
    this.state = {
      currentSlideIndex: props.currentSlideIndex,//liuxue,uk,usa,au,language,culture
    };
  }
  onNextSlide = () => {
    //var i = this.state.currentSlideIndex + 1;
    //this.props.onClickNextcurrentSLide(i)
    document.getElementById('mainwrap').scrollBy({
      top: 20, // 垂直滚动500px
      left: 0, // 水平位置（这里不改变）
      behavior: 'smooth' // 平滑滚动效果
    });
  }
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_18']} flex-col`}>
          <div className={`${styles['box_19']} flex-row indexResponse_box_19__QnBIi`}>
            <div className={`${styles['image-wrapper_4']} flex-col justify-between indexResponse_image-wrapper_4__vVklQ`}>
              <img
                onClick={() => this.onNextSlide()}
                className={`clickable`}
                src={
                  require('./assets/img/SketchPngd08825a154ca581054fc75372f7edca364d9ed279fa43b889c445ea09526f894.png')
                }

              />
              <img
                className={`clickable`}
                src={
                  require('./assets/img/SketchPng1043bd5c5c67398c345b9723acb5e8d9485cafdf0c89eca728c1f52603b44a47.png')
                }
                onClick={this.handleButtonClick}
              />
            </div>
            <div className={`${styles['box_3']} flex-col indexResponse_box_3__T9fAr`}>
              <div className={`${styles['group_4']} flex-col indexResponse_group_4__CDFXq`} />
            </div>
            <img
              className={`${styles['image_6']} indexResponse_image_6__1q8nq`}
              src={
                require('./assets/img/SketchPng2a8675b81e27764903f1304c221689ced87ea3dbbfa3996be807f85d83d596d3.png')
              }
            />
          </div>
          <div className={`${styles['block_3']} flex-col fade-out`}>
            <div className={`${styles['section_5']} flex-row justify-between indexResponse_section_5__3ffSf`}>
              <span className={`${styles['paragraph_1']} animate_slide`}>
                哈喽，我是小麦，
                <br />
                有需要我帮忙的地方嘛？
              </span>
              <div className={`${styles['section_6']} flex-col justify-between indexResponse_section_6__Rgrlh`}>
                <span className={`${styles['text_7']} indexResponse_text_7__xYxH2`}>Multi-Agent&nbsp;System</span>
                <div className={`${styles['image-text_7']} flex-col justify-between indexResponse_image-text_7__3KsZj`}>
                  <div className={`${styles['box_4']} flex-col`} />
                  <span className={`${styles['text-group_1']}`}>首页</span>
                </div>
              </div>
            </div>
            <div className={`${styles['section_7']} flex-row justify-between indexResponse_section_7__1pniE`}>
              <div className={`${styles['section_1']} flex-row  indexResponse_section_1__2jgJX`}>
                <div className={`${styles['image-text_8']} flex-row justify-between indexResponse_image-text_8__F_Onh`}>
                  <span className={`${styles['text-group_2']}`}>点击咨询</span>
                  <img
                    className={`${styles['label_1']}`}
                    src={
                      require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                    }
                  />
                </div>
              </div>
              <div className={`${styles['box_20']} flex-col justify-between`}>
                <div className={`${styles['group_8']} flex-col`} />
                <div className={`${styles['group_9']} flex-col`} />
              </div>
            </div>
            <div className={`${styles['section_8']} flex-row`}>
              <div className={`${styles['group_11']} flex-col`} />
            </div>
            <div className={`${styles['section_9']} flex-row`}>
              <div className={`${styles['group_13']} flex-col`} />
            </div>
          </div>
          <img
            className={`${styles['image_7']} fade-out `}
            src={
              require('./assets/img/SketchPnge75d179e51982c2c0ca7c30eca70cd14136f77e2292d42a3cf1ead2a5ef9eb01.png')
            }
          />
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_1;

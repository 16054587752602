'use strict';

import React, { Component } from 'react';

import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_5 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles.page} flex-col indexResponse_page__22n8A`}>
        <div className='arrow_up'></div>
        <span className={`${styles['text_7']} indexResponse_text_7__2-zwz`}>Multi-Agent&nbsp;System</span>
        <div className={`${styles['box_5']} flex-col indexResponse_box_5__1yOvY`}>
          <div className={`clickable ${styles['box_6']} flex-col indexResponse_box_6__1JAFq`}>
            <div className={`${styles['section_1']} flex-col indexResponse_section_1__2vqlT`}>

              <div className={`${styles['box_23']} flex-row`}>
                <div className={`${styles['box_8']} flex-col`} />
              </div>
              <div className={`clickable ${styles['box_24']} flex-row justify-between`}>
                <div className={`${styles['box_25']} flex-col justify-between`}>
                  <div className={`${styles['box_11']} flex-col`} />
                  <div className={`${styles['box_12']} flex-col`} />
                </div>

              </div>
              <div className={`${styles['box_26']} flex-row`}>
                <div className={`${styles['group_2']} flex-col`} />
              </div>
              <div className={`${styles['box_27']} flex-row`}>
                <div className={`${styles['section_2']} flex-col`} />
              </div>


            </div>
          </div>
        </div>
        <div className={`${styles['box_22']} flex-col indexResponse_box_22__1j5c4`}>
          <div className={`${styles['section_7']}  slide-left`}>
            <div className={`${styles['box_2']} flex-col indexResponse_box_2__8d_Sh`}>
              <span className={`${styles['text_1']} indexResponse_text_1__2zmYt`}>合作院校</span>


              <div className={`${styles['list_3']} flex-row`}>
                <div className={`clickable ${styles['list-items_1-0']} flex-col indexResponse_list-items_1-0__CozHx`}>
                  <div className={`${styles['text-wrapper_3-0']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-0']} indexResponse_text_4-0__f8eG-`}>英国艺术院校</span>
                  </div>
                </div>
                <div className={`clickable ${styles['list-items_1-0']} flex-col indexResponse_list-items_1-0__CozHx`}>
                  <div className={`${styles['text-wrapper_3-0']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-0']} indexResponse_text_4-0__f8eG-`}>美国艺术院校</span>
                  </div>
                </div>

                <div className={`clickable ${styles['list-items_1-0']} flex-col indexResponse_list-items_1-0__CozHx`}>
                  <div className={`${styles['text-wrapper_3-0']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-0']} indexResponse_text_4-0__f8eG-`}>亚洲艺术院校</span>
                  </div>
                </div>
                <div className={`clickable ${styles['list-items_1-1']} flex-col indexResponse_list-items_1-1__2NviJ`}>
                  <div className={`${styles['text-wrapper_3-1']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-1']} indexResponse_text_4-1__7l-uA`}>澳洲艺术院校</span>
                  </div>
                </div>
                <div className={`clickable ${styles['list-items_1-2']} flex-col indexResponse_list-items_1-2__360nA`}>
                  <div className={`${styles['text-wrapper_3-2']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-2']} indexResponse_text_4-2__26MAT`}>加拿大艺术院校</span>
                  </div>
                </div>
                <div className={`clickable ${styles['list-items_1-2']} flex-col indexResponse_list-items_1-2__360nA`}>
                  <div className={`${styles['text-wrapper_3-2']} flex-col indexResponse_text-wrapper_3-0__ai8yf`}>
                    <span className={`${styles['text_4-2']} indexResponse_text_4-2__26MAT`}>加拿大艺术院校</span>
                  </div>
                </div>
              </div>


            </div>



          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_5;

// ScrollContainer.js - 基类组件
import React from 'react';

import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import CountryMenu from './components/countryMenu.jsx';
import LiuxueHome from './view/lanhu_211xuanzeguojiahaiwailiuxue/index.jsx';
import UKHome from './view/lanhu_212xuanzeguojiayingguo/index.jsx';


import styles from './assets/countryResponse.module.css';

class Country extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: 'liuxue',//liuxue,uk,usa,au,language,culture
        };
    }
    render() {
        return (
            <div className={`flex-col`}>
                <Header activeMenu='1' />
                <div className={`${styles['group_8']} flex-col countryResponse_group_8__1T4YJ`}>
                    <div className={`${styles['group_9']} flex-row justify-between countryResponse_group_9__3jOrw`}>
                        <CountryMenu activeMenu={this.state.activeMenu} onActiveMenu={(activeMenu) => this.setState({ activeMenu })} />
                        {this.state.activeMenu == 'liuxue' && <LiuxueHome />}
                        {this.state.activeMenu == 'uk' && <UKHome />}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Country;
'use strict';

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import styles from './assets/indexResponse.module.css';
import { transform } from '@babel/core';

class CarouselImg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 1,
      ShowSlideUnit: 5,
      totalSlides: 15,
      sidesNumber: 1 //多少屏
    }
  }
  componentDidMount() {
    var m = this.state.totalSlides;
    var n = this.state.ShowSlideUnit;
    var k = m % n == 0 ? parseInt(m / n) : parseInt(m / n) + 1;
    this.setState({ sidesNumber: k })
  }

  onPageNext = () => {
    var o = document.getElementById("slidepicture");

    var i = this.state.slideIndex;

    if (i < this.state.sidesNumber) {
      i = i + 1

      var k = parseInt(1.61 * (o.clientWidth / 63.75));
      var dic = -(i - 1) * (o.clientWidth + k);
      this.setState({ slideIndex: i })

      o.style.transform = "translateX(" + dic + "px)"
    }

  }
  onPagePrev = () => {
    var o = document.getElementById("slidepicture");
    var i = this.state.slideIndex;


    i = i - 1
    var k = parseInt(1.61 * (o.clientWidth / 63.75));
    var dic = 0;
    if (i == 0) {
      dic = 0
    }
    else {
      dic = -(i - 1) * (o.clientWidth + k)
    }

    this.setState({ slideIndex: i })
    o.style.transform = "translateX(" + dic + "px)"


  }
  render() {
    return (
      <div className='slidewrap overhide'>
        {/* 第一屏 */}
        <div className={`${styles['section_9']} flex-row justify-between indexResponse_section_9__1Wpyf visible transition`} id="slidepicture">
          <div className={`${styles['group_2']} flex-col clickable`} />
          <div className={`${styles['group_3']} flex-col clickable`} />
          <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
            <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
          </div>
          <div className={`${styles['group_5']} flex-col clickable`} />
          <div className={`${styles['group_6']} flex-col clickable`} />

          {/* 第二屏 */}
          <div className={`${styles['group_3']} flex-col clickable`} />
          <div className={`${styles['group_3']} flex-col clickable`} />
          <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
            <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
          </div>
          <div className={`${styles['group_5']} flex-col clickable`} />
          <div className={`${styles['group_6']} flex-col clickable`} />
          {/* 第三屏 */}
          <div className={`${styles['group_3']} flex-col clickable`} />
          <div className={`${styles['group_3']} flex-col clickable`} />
          <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
            <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
          </div>
          <div className={`${styles['group_5']} flex-col clickable`} />
          <div className={`${styles['group_6']} flex-col clickable`} />

        </div>


        <div className='flex-row  pageArrowWrap'>
          {this.state.slideIndex > 1 && <div className='page-prev clickable ' onClick={() => this.onPagePrev()}></div>}

          {this.state.slideIndex < this.state.sidesNumber && <div className='page-next clickable ' onClick={() => this.onPageNext()}></div>}
        </div>
      </div>
    );
  }
}
export default CarouselImg;

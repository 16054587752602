'use strict';

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import styles from './assets/countryMenuResponse.module.css';

class DDS_lanhupage_CountryMenu extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['section_10']} flex-col justify-between countryMenuResponse_section_10__RaVKI `}>
        <div className={`${styles['text-wrapper_1']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'liuxue' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => { this.props.onActiveMenu('liuxue'); }}>海外留学</span>
        </div>
        <div className={`${styles['text-wrapper_2']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'uk' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => this.props.onActiveMenu('uk')}>英国</span>
        </div>
        <div className={`${styles['text-wrapper_3']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'usa' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => this.props.onActiveMenu('usa')}>美国</span>
        </div>
        <div className={`${styles['text-wrapper_4']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'au' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => this.props.onActiveMenu('au')}>澳大利亚</span>
        </div>
        <div className={`${styles['text-wrapper_5']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'language' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => this.props.onActiveMenu('language')}>语言</span>
        </div>
        <div className={`${styles['text-wrapper_6']} flex-col clickable`}>
          <span className={this.props.activeMenu == 'culture' ? "countryMenuResponse_text_7__1P9jR active " : "countryMenuResponse_text_7__1P9jR"} onClick={() => this.props.onActiveMenu('culture')}>文化</span>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_CountryMenu;
